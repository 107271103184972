<template>
    <dark-card :class="`setup-changes-brand${!user.admin ? ' compact' : ''}`">
        <h3>
            <span class="alert-tags">
                <template v-if="user.admin">
                    <tooltip-component v-if="volumeWarnings.length">
                         <slotted-tag no-close>Volume warning</slotted-tag>
                            <template #tooltip>
                                <div class="setup-changes-brand__volume-alerts" v-if="volumeLimitWarnings.length">
                                    <h4>Volume limit exceeded for</h4>

                                    <ul>
                                       <li v-for="warning in volumeLimitWarnings" :key="brand.id + warning.id + warning.date">
                                           {{ warning.date }}
                                       </li>
                                    </ul>
                                </div>

                                 <div class="setup-changes-brand__volume-alerts" v-if="tempVolumeLimitWarnings.length">
                                     <h4>Temporary volume limit exceeded for</h4>

                                    <ul>
                                       <li v-for="warning in tempVolumeLimitWarnings" :key="brand.id + warning.id + warning.date">
                                           {{ warning.date }}
                                       </li>
                                    </ul>
                                </div>
                            </template>
                    </tooltip-component>
                    <slotted-tag v-if="sampling.length"
                                no-close
                                tooltip="This brand's Crowd sampling was changed during this time period. This may affect the accuracy of your reports.">
                        <i class="symbol-warning"></i> Sampling changes
                    </slotted-tag>
                    <slotted-tag v-if="retrosends.length"
                                no-close
                                tooltip="Retrosends were done during this time period.">
                        <i class="symbol-warning"></i> Retrosends
                    </slotted-tag>
                </template>
                <slotted-tag v-if="brand.archived" no-close>
                    <i class="icon-info-circled"></i> Archived
                </slotted-tag>
            </span>
            <highlight-text :string="brand.shortName || brand.name" :query="highlight" />
        </h3>

        <section class="setup-changes-brand__tags">
            <slotted-tag v-if="solution" no-close>{{solution}}</slotted-tag>
            <slotted-tag no-close>{{capitalise(brand.tier)}} tier</slotted-tag>
            <slotted-tag v-if="brand.category === 'OWN'" no-close>Own brand</slotted-tag>
            <slotted-tag v-if="brand.category === 'COMPETITOR'" no-close>Competitor brand</slotted-tag>
        </section>

        <section class="setup-changes-brand__details">
            <span>Created:</span><deq-date class="setup-changes-brand__data" :date="brand.created" format="ddd, D MMM YYYY HH:mm"/>
            <span>Last updated:</span><deq-date class="setup-changes-brand__data" :date="brand.lastUpdated" format="ddd, D MMM YYYY HH:mm"/>
            <template v-if="brand.archived">
                <span>Archived:</span><deq-date class="setup-changes-brand__data" :date="brand.archived" format="ddd, D MMM YYYY HH:mm"/>
            </template>
        </section>

        <section v-if="brandVolumesExist" class="setup-changes-brand__volume">
            <div class="header-row">
                <h4>
                    Volume usage
                </h4>
                <be-button @click="toggleShowMoreMonthlyVolumeUsage" v-if="monthlyVolumeUsage.length > monthlyVolumeUsageTrunced.length">
                    {{ showMoreMonthlyVolumeUsage ? 'Hide' : 'Show'}}
                    {{ monthlyVolumeUsage.length - monthlyVolumeUsageTrunced.length }} more
                    {{ formatPlural(monthlyVolumeUsage.length - monthlyVolumeUsageTrunced.length, 'month')}}
                </be-button>
            </div>
            <brand-volume-usage :data="volumeChartData" :brand="brand" :grouping="volumes.grouping"></brand-volume-usage>
            <div class="mini-log">
                <table class="mini-log__table">
                    <tr v-for="curMonthVolumeUsage in (showMoreMonthlyVolumeUsage ? monthlyVolumeUsage : monthlyVolumeUsageTrunced)" :key="brand.id + curMonthVolumeUsage.date">
                        <td>
                            <deq-date :date="curMonthVolumeUsage.date" format="MMM YYYY"/>
                        </td>
                        <td>
                            <deq-number :class="{'warning': curMonthVolumeUsage.usage > getVolumeLimitOfMonth(curMonthVolumeUsage)}" :number="curMonthVolumeUsage.usage"/> mentions collected out of <deq-number :number="getVolumeLimitOfMonth(curMonthVolumeUsage)"/>
                            <span v-if="curMonthVolumeUsage.tempLimit"> (temporary limit)</span>
                        </td>
                    </tr>
                </table>
            </div>
        </section>

        <div v-if="user.admin" class="setup-changes-brand__logs">
            <section class="crowd-context">
                <h4>Crowd context: {{brand.name}}</h4>
                <section v-if="brand.tier !== 'ESSENTIALS'">
                    <ul v-if="tempVolumeLimit >= 0">
                        <li>
                            Temporary maximum volume sent to the Crowd: <deq-number :number="tempVolumeLimit"/> mentions
                            <span v-if="tempVolumeLimitDate"> since <deq-date :date="tempVolumeLimitDate"/> </span>
                        </li>
                        <li>
                            Original maximum: <deq-number :number="volumeLimit"/> mentions
                            <span v-if="volumeLimitDate"> set on <deq-date :date="volumeLimitDate"/> </span>
                        </li>
                    </ul>
                    <ul v-else>
                        <li>
                            Maximum volume sent to the Crowd: <deq-number :number="volumeLimit"/> mentions
                            <span v-if="volumeLimitDate"> set on <deq-date :date="volumeLimitDate"/> </span>
                        </li>
                    </ul>
                    <ul v-if="tempSamplePercentage >= 0">
                        <li>
                            <old-tooltip label="This brand is having its mentions sampled at this percentage temporarily, until the end of the month">
                                Temporarily sampling at <deq-percentage :percent="tempSamplePercentage"/>
                                <span v-if="tempSamplePercentageDate"> since <deq-date :date="tempSamplePercentageDate"/> </span>
                            </old-tooltip>
                        </li>
                        <li>
                            <old-tooltip label="This is the rate that the brand's mentions are usually sampled at">
                                Original sampling: <deq-percentage :percent="samplePercentage"/>
                                <span v-if="samplePercentageDate"> since <deq-date :date="samplePercentageDate"/></span>
                            </old-tooltip>
                            <old-tooltip label="If you're unsure why sampling rates have been temporarily changed, please contact us at support@dataeq.com">
                                <i class="symbol-help"></i>
                            </old-tooltip>
                        </li>
                    </ul>
                    <ul v-else>
                        <li>
                            Sampling at: <deq-percentage :percent="samplePercentage"/>
                            <span v-if="samplePercentageDate"> since <deq-date :date="samplePercentageDate"/></span>
                        </li>
                    </ul>
                </section>

                <section v-if="brand.tier === 'TOPICS'">
                    <ul>
                        <li>
                            From which we sample <deq-percentage :percent="topicPercentage"/> for Topics
                            <span v-if="topicPercentageDate">
                                since <deq-date :date="topicPercentageDate"/>
                            </span>
                        </li>
                    </ul>
                </section>
            </section>

            <section v-if="sampling.length">
                <div class="header-row">
                    <h4>Sampling changes</h4>
                    <be-button @click="toggleShowMoreSamplingChanges" v-if="sampling.length > samplingTrunced.length">
                        {{ showMoreSamplingChanges ? 'Hide' : 'Show'}}
                        {{ sampling.length - samplingTrunced.length }} more
                        {{ formatPlural(sampling.length - samplingTrunced.length, 'sampling change') }}
                    </be-button>
                </div>
                <div class="mini-log">
                    <table class="mini-log__table">
                        <brand-sampling-log v-for="log in (showMoreSamplingChanges ? sampling : samplingTrunced)"
                                            :key="log.date + ':' + log.metadata.brandId"
                                            :log="log"/>
                    </table>
                </div>
            </section>

            <section v-if="retrosends.length">
                <div class="header-row">
                    <h4>
                        Retrosends
                    </h4>
                    <be-button @click="toggleShowMoreRetrosends" v-if="retrosends.length > retrosendsTrunced.length">
                        {{ showMoreRetrosends ? 'Hide' : 'Show'}}
                        {{ retrosends.length - retrosendsTrunced.length }} more
                        {{ formatPlural(retrosends.length - retrosendsTrunced.length, 'retrosend')}}
                    </be-button>
                </div>
                <div class="mini-log">
                    <table class="mini-log__table">
                        <retrosend-log
                            v-for="retrosend in (showMoreRetrosends ? retrosends : retrosendsTrunced)"
                            :log="retrosend"
                            :key="retrosend.userId + ':' + retrosend.date"
                        />
                    </table>
                </div>
            </section>

        </div>
    </dark-card>
</template>

<script>
import DeqDate from "../../components/formatters/DeqDate";
import SlottedTag from "../../components/tags/SlottedTag";
import BrandSamplingLog from "./BrandSamplingLog";
import RetrosendLog from "./RetrosendLog";
import DarkCard from "@/components/cards/DarkCard";
import {getSegmentList} from "@/app/utils/Segments";
import {mapState} from "vuex";
import {formatPlural} from "@/app/utils/Format";
import OldTooltip from "@/components/tooltip/OldTooltip";
import DeqNumber from "@/components/formatters/DeqNumber";
import DeqPercentage from "@/components/formatters/DeqPercentage";
import HighlightText from "@/components/formatters/HighlightText";
import TooltipComponent from "@/components/tooltip/TooltipComponent";
import moment from "moment";
import {getBrandVolumeWarning} from "@/store/vuex/dataChecks";
import BrandVolumeUsage from "@/setup/changes/BrandVolumeUsage";
import BeButton from "@/components/buttons/BeButton";
import {capitalise} from "../../app/utils/StringUtils";


export default {
        components: {
            BeButton,
            BrandVolumeUsage,
            TooltipComponent,
            HighlightText,
            DeqPercentage,
            DeqNumber,
            OldTooltip,
            DarkCard, BrandSamplingLog, SlottedTag, DeqDate, RetrosendLog},
        props: {
            brand: {
                type: Object,
                required: true
            },
            sampling: {
                type: Array,
                required: true
            },
            retrosends: {
                type: Array,
                required: true
            },
            volumes: {
                type: Object
            },
            dateRange: {
                type: Object
            },
            highlight: {
                type: String
            }
        },

        data() {
            return {
                solution: null,
                showMoreSamplingChanges: false,
                showMoreRetrosends: false,
                showMoreMonthlyVolumeUsage: false,
                volumeWarnings: [],
                tabSizeObserver: null
            }
        },

        watch: {
            monthlyVolumeUsage() {
                this.volumeWarnings = [];

                this.monthlyVolumeUsage.forEach(monthUsage => {
                    let tempLimit = monthUsage.tempLimit;
                    let volumeLimit = monthUsage.limit ?? this.brand.volumeLimit;

                    let warning = getBrandVolumeWarning(this.brand, monthUsage.usage, volumeLimit, tempLimit);

                    if (warning) {
                        this.volumeWarnings.push({
                            id: warning.id,
                            date: moment(monthUsage.date).format("MMMM YYYY")
                        });
                    }
                });
            }
        },

        mounted() {
            this.getSolution();
        },

        computed: {
            ...mapState(['user']),

            samplingTrunced() {
                return this.sampling.slice(0,5)
            },
            retrosendsTrunced() {
                return this.retrosends.slice(0,5)
            },

            monthlyVolumeUsageTrunced() {
                return this.monthlyVolumeUsage.slice(0,5);
            },

            dailyVolumeUsage() {
                return this.volumes?.dailyUsage ?? [];
            },

            monthlyVolumeUsage() {
                return this.volumes?.monthlyUsage ?? [];
            },

            volumeChartData() {
                return this.dailyVolumeUsage.length ? this.dailyVolumeUsage : this.monthlyVolumeUsage;
            },

            brandVolumesExist() {
                return (this.volumes ? !!Object.keys(this.volumes)?.length : false) && (this.brand ? !!Object.keys(this.brand)?.length : false);
            },

            currentMonth() {
                return moment().month(moment().month()).format('MMMM')
            },

            volumeLimitWarnings() {
                return this.volumeWarnings.filter(warning => {
                    return warning.id === "BRAND_VOLUME_OVER_LIMIT";
                });
            },

            tempVolumeLimitWarnings() {
                return this.volumeWarnings.filter(warning => {
                    return warning.id === "BRAND_VOLUME_OVER_TEMP_LIMIT";
                });
            },

            volumeLimitObject() {
                const limits = this.sampling
                    .filter(s => s.metadata.to.volumeLimit !== undefined &&
                        s.metadata.to.volumeLimit !== s.metadata.from.volumeLimit);
                if (!limits.length) return null;
                return limits[0];
            },

            volumeLimit() {
                const limit = this.volumeLimitObject;
                if (!limit) return this.brand.volumeLimit;
                return parseInt(limit.metadata.to.volumeLimit);
            },

            volumeLimitDate() {
                const limit = this.volumeLimitObject;
                if (!limit) return null;
                return limit.date;
            },

            tempVolumeObject() {
                const limits = this.sampling
                    .filter(s => s.metadata.to.tempVolumeLimit !== s.metadata.from.tempVolumeLimit);
                if (!limits.length) return null;
                return limits[0];
            },

            tempVolumeLimit() {
                const limit = this.tempVolumeObject;
                if (!limit) return this.brand.tempVolumeLimit;
                return parseInt(limit.metadata.to.tempVolumeLimit);
            },

            tempVolumeLimitDate() {
                const limit = this.tempVolumeObject;
                if (!limit) return null;
                return limit.date;
            },

            tempSampleObject() {
                const limits = this.sampling
                    .filter(s => s.metadata.to.tempCrowdSamplePercentage !== s.metadata.from.tempCrowdSamplePercentage);
                if (!limits.length) return null;
                return limits[0];
            },

            tempSamplePercentage() {
                const limit = this.tempSampleObject;
                if (!limit) return this.brand.tempCrowdSamplePercentage;
                return parseInt(limit.metadata.to.tempCrowdSamplePercentage);
            },

            tempSamplePercentageDate() {
                const limit = this.tempSampleObject;
                if (!limit) return null;
                return limit.date;
            },

            sampleObject() {
                const limits = this.sampling
                    .filter(s => s.metadata.to.crowdSamplePercentage !== undefined &&
                        s.metadata.to.crowdSamplePercentage !== s.metadata.from.crowdSamplePercentage);
                if (!limits.length) return null;
                return limits[0];
            },

            samplePercentage() {
                const limit = this.sampleObject;
                if (!limit) return this.brand.crowdSamplePercentage;
                return parseInt(limit.metadata.to.crowdSamplePercentage);
            },

            samplePercentageDate() {
                const limit = this.sampleObject;
                if (!limit) return null;
                return limit.date;
            },

            topicSampleObject() {
                const limits = this.sampling
                    .filter(s => s.metadata.to.crowdTopicPercentage !== undefined &&
                        s.metadata.to.crowdTopicPercentage !== s.metadata.from.crowdTopicPercentage);
                if (!limits.length) return null;
                return limits[0];
            },

            topicPercentage() {
                const limit = this.topicSampleObject;
                if (!limit) return this.brand.crowdTopicPercentage;
                return parseInt(limit.metadata.to.crowdTopicPercentage);
            },

            topicPercentageDate() {
                const limit = this.topicSampleObject;
                if (!limit) return null;
                return limit.date;
            },
        },

        methods: {
            capitalise,
            formatPlural,

            getVolumeLimitOfMonth(monthUsage) {
                let useBrandLimit = monthUsage.limit === null || monthUsage.limit === undefined;
                if (useBrandLimit) {
                    if (moment(monthUsage.date).format("MM YYYY") === moment().format("MM YYYY")) {
                        return this.brand.tempVolumeLimit ?? this.brand.volumeLimit;
                    }
                    return this.brand.volumeLimit;
                }

                return monthUsage.tempLimit ?? monthUsage.limit;
            },

            getSolution() {
                let hasCx = false;
                let hasConduct = false;

                if (this.brand.activeSegmentListIds) {
                    for (const id of this.brand.activeSegmentListIds) {
                        const segment = getSegmentList(id);
                        if (segment && segment.segmentType.id === "CX_LIST") hasCx = true;
                        if (segment && segment.segmentType.id === "CONDUCT_LIST") {
                            hasConduct = true;
                            break;
                        }
                    }
                }

                if (hasConduct) {
                    this.solution = "Risk and reputation";
                } else if (hasCx) {
                    this.solution = "Customer experience"
                }
            },
            toggleShowMoreSamplingChanges() {
                this.showMoreSamplingChanges = !this.showMoreSamplingChanges
            },
            toggleShowMoreMonthlyVolumeUsage() {
                this.showMoreMonthlyVolumeUsage = !this.showMoreMonthlyVolumeUsage;
            },
            toggleShowMoreRetrosends() {
                this.showMoreRetrosends = !this.showMoreRetrosends
            }

        }
    }
</script>

<style scoped lang="sass">
    @import './mini-log'

    @mixin half-width-block
        width: 49%
        margin-bottom: 2%
        &:nth-child(2n)
            margin-right: 0

    @mixin third-width-block
        width: 32%
        margin-bottom: 2%
        &:nth-child(3n)
            margin-right: 0

    @mixin full-width-block
        width: 100%
        flex-grow: 1
        margin-right: 0
        &:nth-child(2n)
            margin-right: 0
        &:nth-child(3n)
            margin-right: 0
        margin-bottom: 20px

    .setup-changes-brand
        .symbol-warning
            color: var(--be-colour-warning)
        .be-tooltip
            width: auto

        +half-width-block
        @media screen and (max-width: 1280px)
            +full-width-block

        &.compact
            +third-width-block
            @media screen and (max-width: 1280px)
                +half-width-block
            @media screen and (max-width: 900px)
                +full-width-block

        .header-row
            margin: 2em -10px 10px -10px
            padding: 0 10px
            display: flex
            align-items: center
            justify-content: space-between

            h4
                margin: 0


    .setup-changes-brand__logs
        .crowd-context
            margin-top: 20px

    .setup-changes-brand__tags
        margin: 0 0 15px -5px
        .be-tag + .be-tag
            margin-left: 5px

    .setup-changes-brand__volume
        span.be-number
            color: #fff

        .warning
            color: var(--be-colour-warning) !important

    .setup-changes-brand__volume-alerts
        h4
            margin: 0

    .setup-changes-brand__details
        display: grid
        grid-template-columns: auto 1fr
        column-gap: 10px
        .setup-changes-brand__data
            color: white
        .warning
            color: var(--be-colour-warning)

    .mini-log
        +mini-log

    ul
        margin-bottom: 0
        margin-top: 0

    h3, h4
        color: white

    h3
        margin: 0 0 1em
        line-height: 1.2em
        ::v-deep .highlight
            border-radius: 3px
            background: fade-out(#AEE15D, 0.77)
            color: #fff
            padding-right: 3px
            padding-left: 3px
            margin-left: -3px
            margin-right: -3px
        .alert-tags
            float: right
            font-size: 0.8rem
            .be-tag
                margin-left: 12px
                &:first-child
                    margin-left: 0
    h4
        font-size: 1.08em
        margin-top: 2em
        .btn
            float: right
            font-size: 0.8em

</style>