<template>
    <section class="beef-tooltip fantastic-chart--tooltip">
        <strong><deq-number :number="data.usage"/></strong>
        {{ formatPlural(data.usage, 'mention') }} collected {{ dateFormat === 'MMMM YYYY' ? 'in' : 'on' }}
        <strong><deq-date :date="data.date" :format="dateFormat"></deq-date></strong>
    </section>
</template>

<script>
import {formatDate, formatPlural} from "@/app/utils/Format";
import DeqNumber from "@/components/formatters/DeqNumber";
import DeqDate from "@/components/formatters/DeqDate";

export default {
    name: "BrandVolumeChartTooltip",
    components: {DeqDate, DeqNumber},
    props: {
        data: Object,
        dateFormat: String
    },

    methods: {
        formatDate,
        formatPlural
    }
}
</script>

<style scoped>

</style>