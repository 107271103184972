<template>
    <tr class="brand-sampling-log">
        <td class="brand-sampling-log__data">
            <deq-date :date="log.date" format="ddd D MMM, YYYY HH:mm"/>
            <deq-user :user="log.user"/>
        </td>

        <td class="brand-sampling-log__details">
            <section class="brand-sampling-log__reasons">
                <section v-if="tierChanged">
                    Product tier changed
                    <template v-if="log.metadata.from.tier">
                        from {{log.metadata.from.tier ? capitalise(log.metadata.from.tier) : '-'}}
                    </template>
                        to {{log.metadata.to.tier ? capitalise(log.metadata.to.tier) : '-'}}
                </section>
                <section v-if="volumeChanged">
                    Paid for volume changed from
                    <deq-number :number="parseInt(log.metadata.from.volumeLimit)"/>
                    to
                    <deq-number :number="parseInt(log.metadata.to.volumeLimit)"/>
                </section>
                <section v-if="tempVolumeChanged">
                <span v-if="log.metadata.from.tempVolumeLimit === undefined">
                    A temporary volume limit of <deq-number :number="parseInt(log.metadata.to.tempVolumeLimit)"/>
                    was applied.
                </span>
                    <span v-else-if="log.metadata.to.tempVolumeLimit === undefined">
                    The temporary volume limit was removed
                    (it was <deq-number :number="parseInt(log.metadata.from.tempVolumeLimit)"/>)
                </span>
                    <span v-else>
                    The temporary volume limit was changed from
                    <deq-number :number="parseInt(log.metadata.from.tempVolumeLimit)"/>
                    to
                    <deq-number :number="parseInt(log.metadata.to.tempVolumeLimit)"/>
                </span>
                </section>
                <section v-if="crowdSampleChanged">
                    Paid sampling percentage changed from
                    <deq-percentage :percent="parseFloat(log.metadata.from.crowdSamplePercentage)"/>
                    to
                    <deq-percentage :percent="parseFloat(log.metadata.to.crowdSamplePercentage)"/>
                </section>
                <section v-if="tempCrowdSampleChanged">
                    <span v-if="log.metadata.from.tempCrowdSamplePercentage === undefined">
                        A temporary sampling rate of <deq-percentage :percent="parseFloat(log.metadata.to.tempCrowdSamplePercentage)"/>
                        was applied.
                    </span>
                    <span v-else-if="log.metadata.to.tempCrowdSamplePercentage === undefined">
                        The temporary sampling rate was removed
                        (it was <deq-percentage :percent="parseFloat(log.metadata.from.tempCrowdSamplePercentage)"/>)
                    </span>
                    <span v-else>
                        The temporary sampling rate was changed from
                        <deq-percentage :percent="parseFloat(log.metadata.from.tempCrowdSamplePercentage)"/>
                        to
                        <deq-percentage :percent="parseFloat(log.metadata.to.tempCrowdSamplePercentage)"/>
                    </span>
                    <section v-if="topicSampleChanged">
                        Paid topic sampling has been changed from
                        <deq-percentage :percent="parseFloat(log.metadata.from.crowdTopicPercentage)"/>
                        to
                        <deq-percentage :percent="parseFloat(log.metadata.to.crowdTopicPercentage)"/>
                    </section>
                </section>
            </section>

            <section v-if="user.admin" class="brand-sampling-log__markdown">
                <markdown-text :text="log.comment"/>
            </section>
        </td>
    </tr>
</template>

<script>
import {mapState} from "vuex";
import MarkdownText from "@/components/MarkdownText";
import DeqDate from "@/components/formatters/DeqDate";
import DeqNumber from "@/components/formatters/DeqNumber";
import DeqUser from "@/components/formatters/DeqUser";
import DeqPercentage from "@/components/formatters/DeqPercentage";
import {capitalise} from "@/app/utils/StringUtils";

export default {
    name: "BrandSamplingLog",
    methods: {capitalise},
    components: {DeqUser, DeqPercentage, DeqNumber, DeqDate, MarkdownText},
    props: {
        log: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState(['user']),

        tierChanged() {
            return this.log.metadata.from.tier !== this.log.metadata.to.tier;
        },

        volumeChanged() {
            return this.log.metadata.from.volumeLimit !== this.log.metadata.to.volumeLimit;
        },

        tempVolumeChanged() {
            return this.log.metadata.from.tempVolumeLimit !== this.log.metadata.to.tempVolumeLimit;
        },

        crowdSampleChanged() {
            return this.log.metadata.from.crowdSamplePercentage !== this.log.metadata.to.crowdSamplePercentage;
        },

        tempCrowdSampleChanged() {
            const fromPresent = this.log.metadata.from.tempCrowdSamplePercentage !== null && this.log.metadata.from.tempCrowdSamplePercentage !== undefined;
            const toPresent = this.log.metadata.to.tempCrowdSamplePercentage !== null && this.log.metadata.to.tempCrowdSamplePercentage !== undefined;
            if (fromPresent !== toPresent) return true
            if (!fromPresent && !toPresent) return false

            return parseFloat(this.log.metadata.from.tempCrowdSamplePercentage) !== parseFloat(this.log.metadata.to.tempCrowdSamplePercentage);
        },

        topicSampleChanged() {
            return parseFloat(this.log.metadata.from.crowdTopicPercentage) !== parseFloat(this.log.metadata.to.crowdTopicPercentage);

        }

    }
}

</script>

<style scoped lang="sass">

    .brand-sampling-log__data
        .be-user,
        .be-date
            white-space: nowrap

    .brand-sampling-log__details
        color: #fff

</style>