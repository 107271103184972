<template>
    <div class="brand-volume-chart">
        <div class="brand-volume-chart__chart" ref="chart">
        </div>
    </div>
</template>

<script>
import * as b3js from "brandseyejs";
import {formatDate, formatNumber, formatPlural} from "@/app/utils/Format";
import moment from "moment";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import BrandVolumeChartTooltip from "@/setup/changes/BrandVolumeChartTooltip";

export default {
    name: "BrandVolumeUsage",
    props: {
        volumes: Object,
        grouping: {
            required: true
        },
        brand: {
            type: Object,
            required: true
        },
        dateRange: {
            type: Object
        },
        data: {
            required: true
        }
    },

    data() {
        return {
            beChart: null
        }
    },

    watch: {
        data() {
            this.render();
        }
    },

    mounted() {
        this.tabSizeObserver = new ResizeObserver(() => this.redraw());
        this.tabSizeObserver?.observe(this.$el);
    },

    beforeDestroy() {
        this.tabSizeObserver?.disconnect();
    },

    methods: {
        formatPlural,

        render() {
            if (!this.data?.length) return;

            const chart = this.$refs.chart;
            if (!chart) return; // Might have moved on from the page.

            chart.style.opacity = 0;

            this.beChart ??= new b3js.chart();

            let chartGeometry = this.grouping === 'month' ? b3js.histogram().padding(this.data.length / 100) : b3js.line()

            if (chart) {
                this.beChart
                    .reset()
                    .geometry(chartGeometry)
                    .scaleX(b3js.scaleTime())
                    .data(this.data)
                    .element(chart)
                    .x(d => d.date)
                    .y(d => d.usage)
                    .formatY(val => formatNumber(val))
                    .formatX((val, i) => {
                        if (this.grouping === 'month') {
                            if (moment(val).month() === 0) return formatDate(val, 'MMM ’YY');
                            return formatDate(val, 'MMM');
                        } else {
                            const numberOfDays = this.data.length;
                            const firstOrLast = i === 0 || i === this.data.length - 1;

                            let format = numberOfDays > 7 ? "D" : "ddd D";
                            if (firstOrLast) format = "MMM D";
                            else if (this.data.length > 10) {
                                format = moment(val).isoWeekday() !== 1 ? " " : "ddd D";
                            }

                            if (moment(val).isSameOrAfter(moment().startOf('day'))) return "Today";
                            return formatDate(val, format);
                        }
                    })
                    .width(chart.clientWidth)
                    .height(chart.clientHeight + 20)
                    .showLegend(false)
                    .individualColours(() => "#c0c0c0")
                    .render();

                this.beChart.dispatch().on("tooltipShow", event => {
                    showTooltipComponent(event.e.currentTarget,
                        BrandVolumeChartTooltip,
                        {
                            data: event.point,
                            dateFormat: this.grouping === 'month' ? "MMMM YYYY" : "dddd, MMMM D, YYYY"
                        });
                });

                chart.style.opacity = 1;
            }
        },

        /**
         * This clears the chart element, waits a few seconds, and th en redraws.
         * This is useful if you want to resize the chart.
         */
        async redraw() {
            if (this.$refs.chart) {
                this.$refs.chart.innerHTML = '';
            }

            let timeout;
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                this.render();
            }, 250);
        }
    }
}
</script>

<style scoped lang="sass">

.brand-volume-chart
    border-top: 1px solid rgba(192, 192, 192, 0.25)
    margin: 10px -10px 20px -10px
    overflow: hidden

    &__chart
        height: 120px

        ::v-deep svg .x-axis text
            opacity: 0

        &:hover
            ::v-deep svg .x-axis text
                opacity: 1

            ::v-deep .line-highlight
                cursor: auto !important

            ::v-deep .bar
                cursor: auto !important

</style>