import { render, staticRenderFns } from "./BrandVolumeUsage.vue?vue&type=template&id=cb0075f4&scoped=true"
import script from "./BrandVolumeUsage.vue?vue&type=script&lang=js"
export * from "./BrandVolumeUsage.vue?vue&type=script&lang=js"
import style0 from "./BrandVolumeUsage.vue?vue&type=style&index=0&id=cb0075f4&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb0075f4",
  null
  
)

export default component.exports